import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Box,
} from '@mui/material';

import { StaticImage, getImage, GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import { courses as types } from '@codesass/types';

import Link from 'modules/ui/components/Link';

type CourseItemProps = types.CourseListItem;

const CourseItem = ({
  title,
  slug,
  image,
  price,
  promotion,
}: CourseItemProps) => {
  const courseImage = getImage(image);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      role="listitem"
      aria-label={title}
      component={motion.div}
      whileHover={{ scale: 1.1 }}
    >
      <Link to={`/courses/${slug}`} underline="none" aria-label={title}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            cursor: 'pointer',
          }}
        >
          {courseImage && (
            <GatsbyImage image={courseImage} title={title} alt={title} />
          )}
          <CardContent>
            <Typography gutterBottom variant="body1" component="div">
              {title}
            </Typography>
          </CardContent>
          {/* <CardHeader
            sx={{ py: 0 }}
            avatar={
              <Avatar aria-label="creator" sx={{ width: 30, height: 30 }}>
                <StaticImage
                  src="../../../assets/images/avatar.jpeg"
                  alt="Creator"
                />
              </Avatar>
            }
            title="Nuttavut T."
          /> */}
          <Box
            sx={{
              p: 2,
              mt: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              fontWeight="500"
              variant="body2"
              color="GrayText"
              sx={promotion ? { textDecoration: 'line-through', mr: 1 } : {}}
            >
              {price.toLocaleString()}
            </Typography>
            {promotion && (
              <Typography
                variant="subtitle1"
                color="CaptionText"
                fontWeight="bold"
              >
                {`${promotion.toLocaleString()}.-`}
              </Typography>
            )}
          </Box>
        </Card>
      </Link>
    </Grid>
  );
};

export default CourseItem;
