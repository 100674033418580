import {
  courses as coursesTypes,
  shared as sharedTypes,
} from '@codesass/types';

import CourseListPaging from './CourseListPaging';
import CourseList from './CourseList';

type CoursesProps = {
  courses: coursesTypes.CourseListItem[];
  paging: sharedTypes.Pagination;
};

const Courses = ({ courses, paging }: CoursesProps) => {
  return (
    <>
      <CourseList courses={courses}></CourseList>
      <CourseListPaging paging={paging}></CourseListPaging>
    </>
  );
};

export default Courses;
