import { Container, Box, Pagination as MuiPagination } from '@mui/material';

import { shared as types } from '@codesass/types';

type CourseListPagingProps = {
  paging: types.Pagination;
};

const CourseListPaging = ({
  paging: { numPages, currentPage },
}: CourseListPagingProps) => {
  return (
    <Container>
      <Box display="flex" justifyContent="center" my={2}>
        <MuiPagination
          aria-label="Page Navigation"
          count={numPages}
          defaultPage={currentPage}
          variant="outlined"
          color="primary"
        ></MuiPagination>
      </Box>
    </Container>
  );
};

export default CourseListPaging;
