import { graphql, PageProps } from 'gatsby';
import { ReactNode } from 'react';

import {
  courses as coursesTypes,
  shared as sharedTypes,
} from '@codesass/types';

import SEO from 'modules/ui/components/SEO';

import Courses from '../components/Courses';

type CoursesTemplateData = {
  allCoursesYaml: {
    nodes: (Omit<coursesTypes.CourseListItem, 'slug'> & {
      fields: { slug: coursesTypes.Course['slug'] };
    })[];
  };
};

const CoursesTemplate = ({
  pageContext: paging,
  data: {
    allCoursesYaml: { nodes },
  },
}: PageProps<CoursesTemplateData, sharedTypes.Pagination>): ReactNode => {
  const courses = nodes.map(({ fields, ...rest }) => ({
    ...rest,
    ...fields,
  }));

  return <Courses courses={courses} paging={paging}></Courses>;
};

export const query = graphql`
  query CoursesQuery($skip: Int!, $limit: Int!) {
    allCoursesYaml(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        price
        promotion
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 567, layout: CONSTRAINED)
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;

export const Head = () => {
  return (
    <SEO
      title="รวมคอร์สทั้งหมดของ CODE SASS"
      path="/courses"
      type="article"
      desc="รวมคอร์สทั้งหมดของ CODE SASS"
    ></SEO>
  );
};

export default CoursesTemplate;
