import { Container, Grid, Typography } from '@mui/material';

import { courses as types } from '@codesass/types';

import CourseItem from './CourseItem';

type CourseListProps = {
  courses: types.CourseListItem[];
  displayTitle?: boolean;
};

const CourseList = ({ courses, displayTitle = true }: CourseListProps) => {
  return (
    <>
      <Container>
        {displayTitle && (
          <Typography variant="h4" component="h2" py={2} textAlign="center">
            คอร์สคุณภาพ
          </Typography>
        )}
        <Grid container spacing={2} role="list">
          {courses.map(course => (
            <CourseItem key={course.slug} {...course}></CourseItem>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default CourseList;
